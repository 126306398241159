import React from "react";
import Paragraph from "../../components/paragraph";
import SEO from "../../components/seo";
import WorkDetail from "../../components/work-detail";
import WorkDetailImage from "../../components/work-detail-image";
import WorkDetailIntro from "../../components/work-detail-intro";
import WorkDetailText from "../../components/work-detail-text";

import introImageSm from "../../images/work/mail/intro-sm.jpg";
import introImageMd from "../../images/work/mail/intro-md.jpg";
import introImageLg from "../../images/work/mail/intro-lg.jpg";
import contentImage1Sm from "../../images/work/mail/content-1-sm.jpg";
import contentImage1Md from "../../images/work/mail/content-1-md.jpg";
import contentImage1Lg from "../../images/work/mail/content-1-lg.jpg";
import contentImage2Sm from "../../images/work/mail/content-2-sm.jpg";
import contentImage2Md from "../../images/work/mail/content-2-md.jpg";
import contentImage2Lg from "../../images/work/mail/content-2-lg.jpg";
import contentImage3Sm from "../../images/work/mail/content-3-sm.jpg";
import contentImage3Md from "../../images/work/mail/content-3-md.jpg";
import contentImage3Lg from "../../images/work/mail/content-3-lg.jpg";
import contentImage4Sm from "../../images/work/mail/content-4-sm.jpg";
import contentImage4Md from "../../images/work/mail/content-4-md.jpg";
import contentImage4Lg from "../../images/work/mail/content-4-lg.jpg";
import contentImage5Sm from "../../images/work/mail/content-5-sm.jpg";
import contentImage5Md from "../../images/work/mail/content-5-md.jpg";
import contentImage5Lg from "../../images/work/mail/content-5-lg.jpg";
import contentImage6Sm from "../../images/work/mail/content-6-sm.jpg";
import contentImage6Md from "../../images/work/mail/content-6-md.jpg";
import contentImage6Lg from "../../images/work/mail/content-6-lg.jpg";
import contentImage7Sm from "../../images/work/mail/content-7-sm.jpg";
import contentImage7Md from "../../images/work/mail/content-7-md.jpg";
import contentImage7Lg from "../../images/work/mail/content-7-lg.jpg";
import contentImage8Sm from "../../images/work/mail/content-8-sm.jpg";
import contentImage8Md from "../../images/work/mail/content-8-md.jpg";
import contentImage8Lg from "../../images/work/mail/content-8-lg.jpg";
import contentImage9Sm from "../../images/work/mail/content-9-sm.jpg";
import contentImage9Md from "../../images/work/mail/content-9-md.jpg";
import contentImage9Lg from "../../images/work/mail/content-9-lg.jpg";
import contentImage10Sm from "../../images/work/mail/content-10-sm.jpg";
import contentImage10Md from "../../images/work/mail/content-10-md.jpg";
import contentImage10Lg from "../../images/work/mail/content-10-lg.jpg";

const MailPage = () => (
  <>
    <SEO title="Direct Mail Campaign" />
    <WorkDetail title="Direct Mail Campaign">
      <WorkDetailIntro
        title="A Successful Refresh Resulting in Increased Customer Activations"
        description={
          <Paragraph variant="work-detail">
            With an increase in DISH Network’s TV package pricing, this direct
            mail campaign needed a refresh to demonstrate the value of buying
            DISH over competitors with lower prices. The collaboration between
            our design team and the marketing team resulted in a 19% lift in
            customer activations when our new creative was tested against the
            existing champion creative.
          </Paragraph>
        }
        images={{
          sm: introImageSm,
          md: introImageMd,
          lg: introImageLg,
          alt: "Direct mail letter, insert, and envelope.",
        }}
        role="Design"
        client="DISH Network"
        project="mail"
      />
      <WorkDetailText heading="Determining What Value Means to Potential Customers">
        <Paragraph variant="work-detail">
          Consisting of a two-page letter and two brochures contained in an
          envelope, the goal was to redesign the existing creative to better
          portray the value in DISH’s TV service to the rural target market.
        </Paragraph>
        <Paragraph variant="work-detail">
          The design team, consisting of the art director, senior copywriter,
          and myself, met with the marketing client to kick off the project. We
          then brainstormed how to incorporate the client's goals and marketing
          research into the copy and design strategy.
        </Paragraph>
      </WorkDetailText>
      <WorkDetailImage
        imagesLeft={{
          sm: contentImage1Sm,
          md: contentImage1Md,
          lg: contentImage1Lg,
          alt: "White board with notes for concept ideas.",
        }}
        imagesRight={{
          sm: contentImage2Sm,
          md: contentImage2Md,
          lg: contentImage2Lg,
          alt: "White board with brainstorming notes about content layout.",
        }}
        caption="Notes from our brainstorm"
        pairStackedSmall
      />
      <WorkDetailText>
        <Paragraph variant="work-detail">
          After our copywriter finalized copy strategy, I worked on the visual
          design and content structure. Including everything in the set amount
          of space was challenging, but I enjoyed visualizing the information to
          be easy to comprehend.
        </Paragraph>
      </WorkDetailText>
      <WorkDetailImage
        images={{
          sm: contentImage3Sm,
          md: contentImage3Md,
          lg: contentImage3Lg,
          alt: "Two letter pages layered on a surface.",
        }}
      />
      <WorkDetailText>
        <Paragraph variant="work-detail">
          The front of the first letter page sets the tone for the rest of the
          creative and is an overview of the value the customer will receive
          when switching to DISH. I used bold headlines for easy scanning and
          highlighted important details with color.
        </Paragraph>
        <Paragraph variant="work-detail">
          While the gift card and other free extras portrayed the monetary
          value, it was important to show other benefits, such as award-winning
          customer service.
        </Paragraph>
      </WorkDetailText>
      <WorkDetailImage
        images={{
          sm: contentImage4Sm,
          md: contentImage4Md,
          lg: contentImage4Lg,
          alt:
            "Letter page with headline 'Get more from your TV' and prominent gift card imagery.",
        }}
        caption="Front of the first letter page"
      />
      <WorkDetailText>
        <Paragraph variant="work-detail">
          To minimize the price increase, we included a section below to
          highlight the extras included with the package price. My design uses a
          simple grid to showcase them clearly.
        </Paragraph>
      </WorkDetailText>
      <WorkDetailImage
        images={{
          sm: contentImage5Sm,
          md: contentImage5Md,
          lg: contentImage5Lg,
          alt: "Letter page with offer details and imagery.",
        }}
        caption="Back of the first letter page"
      />
      <WorkDetailText>
        <Paragraph variant="work-detail">
          The design of the second letter page focuses on “value stories”, which
          our team developed using existing marketing research to elaborate on
          the specific features most relatable to the customer. To make it more
          engaging to the viewer, I used relevant lifestyle imagery and
          prominent customer reviews.
        </Paragraph>
      </WorkDetailText>
      <WorkDetailImage
        images={{
          sm: contentImage6Sm,
          md: contentImage6Md,
          lg: contentImage6Lg,
          alt:
            "Letter page with headline 'TV made for you' with couple watching TV and offer details.",
        }}
        caption="Front of the second letter page overlaying the first letter"
      />
      <WorkDetailImage
        images={{
          sm: contentImage7Sm,
          md: contentImage7Md,
          lg: contentImage7Lg,
          alt:
            "Letter page with headline 'TV made for your family' with offer details and family watching TV.",
        }}
        caption="Back of the second letter page"
      />
      <WorkDetailText>
        <Paragraph variant="work-detail">
          The bifold brochure has a channel list for all the packages and
          highlights the free voice remote. The double-sided brochure goes into
          more detail on customer service and highlighting DISH authorized
          retailers in local communities.
        </Paragraph>
      </WorkDetailText>
      <WorkDetailImage
        imagesLeft={{
          sm: contentImage8Sm,
          md: contentImage8Md,
          lg: contentImage8Lg,
          alt:
            "Folded brochure with couple watching TV on the cover, and channel lineup list on the inside.",
        }}
        imagesRight={{
          sm: contentImage9Sm,
          md: contentImage9Md,
          lg: contentImage9Lg,
          alt:
            "Back of the folded brochure with information about a free voice remote.",
        }}
        caption="3.5”x6.75” bifold brochure"
        pairStackedSmall
      />
      <WorkDetailImage
        images={{
          sm: contentImage10Sm,
          md: contentImage10Md,
          lg: contentImage10Lg,
          alt:
            "Double-sided brochure with information about cutomer service awards and local service.",
        }}
        caption="3.5”x6.75” double-sided brochure"
      />
      <WorkDetailText heading="The Results">
        <Paragraph variant="work-detail">
          The marketing team tested this new campaign creative against the
          current champion in market. Our new design and strategy performed
          better, increasing customer activations by 19%. It became the new
          champion creative moving forward.
        </Paragraph>
      </WorkDetailText>
    </WorkDetail>
  </>
);

export default MailPage;
